<template>
  <BasicCrud
    :fields="fields"
    :title="$t('Units')"
    path="unit"
    :boolColumns="boolColumns"
    :hideEditAction="!isMaster()"
    :hideDeleteAction="!isMaster()"
    :showNewButton="isMaster()"
  ></BasicCrud>
</template>

<script>

import BasicCrud from '@/components/BasicCrud'
import { mapState } from 'vuex'

export default {
  name: "Unit",

  components: {
    BasicCrud
  },

  computed: mapState({
    userData: state => state.app.userData,
    fields: state => state.unit.fields
  }),

  data() {
    return {
      boolColumns: ['item.active']
    }
  },

  methods: {
    isMaster() {
      return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    }
  }
}

</script>
